<template>
  <a-card :bordered="false">
    <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="图片" name="image" help="且不超过500kb （只能上传一张）">
        <a-upload v-model:fileList="fileList" name="image" accept='' list-type="picture-card" class="avatar-uploader"
          :action="baseUrl+'upload/upload'" :headers='header' @change="handleChange" @preview="handlePreview">
          <div v-if='fileList.length == 0'>
            <PlusOutlined />
            <div class="ant-upload-text">上传图片</div>
          </div>
        </a-upload>
      </a-form-item>
      <a-form-item label="跳转链接类型" name="url_type">
        <a-radio-group v-model:value="form.url_type">
          <a-radio :value="1">系统公告</a-radio>
          <a-radio :value="2">外部链接</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="跳转链接" name="url" :help="form.url_type==2?'需要填写完整链接，如：https://www.baidu.com':''">
        <a-input style="width:300px" placeholder="请输入链接" v-model:value="form.url" v-if="form.url_type==2"></a-input>
        <a-input style="width:300px" placeholder="请选择公告" readonly v-model:value="noticeName" v-if="form.url_type==1">
        </a-input>
        <a-button type="primary" class="m-l-10" @click="visible=true" v-if="form.url_type==1">选择公告</a-button>
      </a-form-item>
      <a-form-item label="通知对象" class="m-t-10" style="margin-bottom:10px">
        <a-radio-group name="radioGroup" v-model:value="form.send_type" :disabled="$route.query.type=='look'">
          <a-radio :value="1">全部品牌</a-radio>
          <a-radio :value="2">指定品牌</a-radio>
          <a-radio :value="3">部分不可见</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="" v-show="form.send_type==2||form.send_type==3" :wrapperCol="{span:18,offset:2}">
        <a-button type="primary" :disabled="$route.query.type=='look'" @click="brandVisible=true">选择</a-button>
        <a-button type="primary" class="m-l-15" ghost @click="showVisible=true">已选</a-button>
      </a-form-item>
      <a-form-item label="排序" name="sort" help="数值越大，排序越靠前；如果数值相同，则按照添加时间由大到小排序显示">
        <a-input-number style="width:300px" placeholder="请输入排序" :min='0' :max="999" :precision='0'
          v-model:value="form.sort"></a-input-number>
      </a-form-item>
      <a-form-item label="显示状态" name="status">
        <a-radio-group v-model:value="form.status" required>
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 8, offset: 2 }">
        <a-button type="primary" @click="onSubmit" :loading='loading'>
          保存
        </a-button>
      </a-form-item>
    </a-form>
  </a-card>
  <!-- 公告弹窗 -->
  <a-modal v-model:visible="visible" title="选择公告" :width='1200' destroyOnClose centered :footer="null">
    <selectNotice v-on:select="noticeSelect" :checkout="false" :selectList="[noticeItem]" />
  </a-modal>
  <a-modal title="查看图片" v-model:visible="previewVisible" :footer="null" centered @cancel="previewVisible=false">
    <img alt="example" style="width: 100%" :src="previewImage" />
  </a-modal>
  <a-modal v-model:visible="showVisible" :width="1000" :zIndex="20000" title="已选品牌商" centered destroyOnClose
    :footer="null">
    <a-table :columns='columns' :data-source='confirmList' rowKey='id' bordered :pagination="pagination"
      @change="changePage">
      <template v-slot:action="{index}">
        <a-button type="link" @click="selectDel(index)">删除</a-button>
      </template>
    </a-table>
  </a-modal>
  <!-- 品牌商弹窗 -->
  <a-modal v-model:visible="brandVisible" title="选择品牌商" :width='1200' :zIndex="20000" destroyOnClose centered
    :footer="null">
    <selectBrand v-on:select="brandSelect" :checkout="true" :selectList="confirmList" />
  </a-modal>
</template>

<script>
  import { PlusOutlined } from '@ant-design/icons-vue'
  import { onMounted, ref, reactive, toRefs, inject } from "vue";
  import { noticeImgAdd, noticeImgEdit, noticeImgDetail, noticeImgBrands } from '@/api/brand.js'
  import { $iscode } from "@/utils/app";
  import { message } from "ant-design-vue";
  import { getToken } from "@/libs/util";
  import config from "@/config";
  import store from "@/store";
  const baseUrl = config.baseUrl[process.env.VUE_APP_type];
  const header = { apiAuth: getToken() };
  // 初始化默认筛选项数值
  let defForm = {
    image: "",
    url_type: 1,
    url: "",
    sort: 0,
    status: 1,
    send_type: 1,
    send: []
  };
  export default {
    components: {
      PlusOutlined
    },
    setup() {
      const ruleForm = ref();
      const form = ref({ ...defForm });
      const $route = inject("$route");
      const $router = inject("$router");
      const state = reactive({
        confirmList: [],
        brandVisible: false,
        previewVisible: false,
        showVisible: false,
        previewImage: "",
        visible: false,
        noticeItem: {},
        noticeName: "",
        info: store.state.user.info,
        fileList: [],
        labelCol: { span: 2 },
        wrapperCol: { span: 18 },
        rules: {
          image: [
            { required: true, message: "请上传图片", },
          ],
          sort: [
            { required: true, message: "请输入排序", },
          ],
          status: [
            { required: true, message: "请选择状态", },
          ]
        },

        pagination: {
          total: 0,
          current: 1,
          pageSize: 5,
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '15', '20', '25'],
          showQuickJumper: true,
          showTotal: (total) => `共 ${total} 条`,
        },
        columns: [
          {
            title: "品牌商编号",
            dataIndex: "brand_no",
            customRender: function ({ record }) {
              return record.brand_no || record.brand_id
            }
          },
          {
            title: "公司名称",
            dataIndex: "company",
          },
          {
            title: "品牌商账号",
            dataIndex: "account",
          },
          {
            title: "操作",
            dataIndex: "action",
            width: 120,
            slots: { customRender: 'action' }
          }
        ]
      });
      //获取详情
      const getMore = async (id) => {
        const hide = message.loading("正在加载中...", 0);
        let res = await noticeImgDetail({ id: id }).then((res) => res.data).catch((error) => error);
        if ($iscode(res)) {
          form.value = res.data;
          form.value.send = Array.isArray(res.data) ? res.data : [];
          state.fileList = [{ url: form.value.image.indexOf(state.info.picdomain) == -1 ? state.info.picdomain + form.value.image : form.value.image }]
        } else {
          message.error(res.msg);
        }
        setTimeout(hide);
      };
      //提交
      const onSubmit = () => {
        ruleForm.value.validate().then(async () => {
          const hide = message.loading("正在加载中...", 0);
          let res;
          if (state.confirmList.length != 0) {
            form.value.send = state.confirmList.map(item => {
              return item.brand_no
            })
          }
          if ($route.query.type == "add") {
            res = await noticeImgAdd({ ...form.value }).then((res) => res.data).catch((error) => error);
          } else {
            res = await noticeImgEdit({ ...form.value }).then((res) => res.data).catch((error) => error);
          }
          setTimeout(hide);
          if ($iscode(res)) {
            message.success(res.msg);
            setTimeout(() => {
              $router.go(-1);
            }, 1000);
          } else {
            message.error(res.msg);
          }
        }).catch((error) => {
          message.error("请完善表单！");
        });
      };
      const handleChange = (info) => {
        if (info.file.status === 'done') {
          state.fileList = info.fileList.splice(info.fileList.length - 1, 1)
          form.value.image = info.file.response.data[0].name
        }
        if (info.file.status === 'error') {
          message.error('图片上传失败！');
        }
        if (info.file.status === 'removed') {
          form.value.image = ''
        }
      }
      const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        state.previewImage = file.url || file.preview;
        state.previewVisible = true;
      }
      const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
      const noticeSelect = (e) => {
        state.noticeItem = e;
        state.noticeName = e.title;
        form.value.url = e.id;
        state.visible = false;
      };
      const changePage = (e) => {
        state.pagination.current = e.current;
        state.pagination.pageSize = e.pageSize;
      }
      const brandSelect = (e) => {
        state.confirmList = e;
        state.brandVisible = false;
        state.pagination.total = state.confirmList.length;
      };
      const selectDel = (index) => {
        state.confirmList.splice(index, 1);
        state.pagination.total = state.confirmList.length;
      }
      const getNoticeBrand = async () => {
        let res = await noticeImgBrands({ id: $route.query.id }).then(res => res.data).catch(error => error)
        if ($iscode(res)) {
          state.confirmList = res.data || [];
          state.pagination.total = state.confirmList.length || 0;
        } else {
          message.error(res.msg)
        }
      }
      onMounted(() => {
        if ($route.query.type == "edit") {
          getMore($route.query.id);
          getNoticeBrand();
        }
      });
      return {
        ...toRefs(state),
        form,
        ruleForm,
        baseUrl,
        header,
        onSubmit,
        handleChange,
        noticeSelect,
        handlePreview,
        changePage,
        brandSelect,
        getNoticeBrand,
        selectDel
      };
    },
  };
</script>